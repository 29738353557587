export const PROFILE_TYPE = {
  PERSONAL: 1,
  COMPANY: 2,
};

export const PROFILE_STATUS = {
  INCOMPLETE: 'incomplete',
  PENDING: 'pending',
  COMPLETE: 'complete',
  NO_SERVICE: 'no_service',
};

export const VERIFICATION_LEVEL = {
  NO_SERVICE: 0,
  STARTER: 1,
  BASIC: 2,
  FULL: 3,
};

export const VERIFICATION_ROUTE_NAME = {
  MAIN: 'verification',
  WELCOME: 'verification-welcome',
  START: 'verification-details',
  ADDRESS: 'verification-address',
  IDENTITY: 'verification-identity',
  POA: 'verification-poa',
  FINANCIAL: 'verification-financial',
  SOF: 'verification-sof',
  CONFIRMATION: 'verification-confirmation',
  SETTING_UP_ACCOUNT: 'verification-setting-up-account',
  COMPLETE: 'verification-complete',
  REVIEW: 'verification-review',
  NO_SERVICE: 'verification-no-service',
  COUNTRY_MISMATCH: 'verification-country-mismatch',
};

export const CURRENCY = {
  EUR: 'EUR',
  GBP: 'GBP',
};

export const ORDER_STORAGE_KEY = 'SWAPIN_WIDGET_ORDER';
export const SELL_ORDER_STORAGE_KEY = 'sell-order';
export const BUY_ORDER_STORAGE_KEY = 'buy-order';

export const CONFIG_KEY = 'swapin-widget-default';
