import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

// FIXME: fix UTC time

export const FORMAT = {
  SERVER_TIMEZONE: 'YYYY-MM-DD HH:mm:ss',
  SERVER: 'YYYY-MM-DD HH:mm:ss',
  SERVER_DATE: 'YYYY-MM-DD',
  LOCAL: 'DD.MM.YYYY HH:mm',
  LOCAL_DATE: 'DD.MM.YYYY',
  TIME: 'HH:mm',
  TIME_SECONDS: 'mm:ss',
};

export const getTimeDiff = (value, fromFormat = FORMAT.SERVER) => dayjs(value, fromFormat).diff(undefined, 'seconds');

export const getUtcNow = (format = FORMAT.SERVER) => dayjs.utc().format(format);

export const getNow = (format = FORMAT.SERVER) => (format ? dayjs().format(format) : dayjs());

export const getDatetime = (value, format, fromFormat) => {
  const valueFormat = value instanceof Date ? undefined : fromFormat;
  return dayjs(value, valueFormat).format(format);
};

export const getLocalDatetime = (
  value,
  format = FORMAT.LOCAL,
  fromFormat = FORMAT.SERVER,
) => getDatetime(value, format, fromFormat);

export const getLocalDate = (value) => getLocalDatetime(value, FORMAT.LOCAL_DATE, FORMAT.SERVER_DATE);

export const getServerDatetime = (
  value,
  format = FORMAT.SERVER,
  fromFormat = FORMAT.LOCAL,
) => getDatetime(value, format, fromFormat);

export const getServerDate = (value) => getServerDatetime(value, FORMAT.SERVER_DATE, FORMAT.LOCAL_DATE);

export const isValidServerDate = (value) => dayjs(value, FORMAT.SERVER_DATE).isValid();

/** @deprecated temp cause of format with timezone */
export const formatDate = (
  value,
  toFormat = 'DD.MM.YYYY HH:mm',
  fromFormat = 'YYYY-MM-DD HH:mm:ss ZZ',
) => dayjs(String(value), fromFormat).format(toFormat);

export { default as dayjs } from 'dayjs';
