import { defineStore } from 'pinia';
import { getCoins } from '@swapin-hub/api/coins';

export const useCoinsStore = defineStore('coins', {
  state: () => ({
    coins: [],
    coinsObject: {},
  }),

  getters: {
    buyCoins: (state) => state.coins.filter((coin) => !!coin.buy),
  },

  actions: {
    async getCoins() {
      const coins = await getCoins();

      this.coinsObject = coins;
      this.coins = Object.values(coins);
    },
  },
});
