import { z as Pe, a9 as T, B as ge, aa as Ee, U as S, u as z, a2 as Q, a1 as X, ab as Y, ac as V, ad as ee, ae as x, af as te, T as ne, ag as A, ah as re, L as oe, ai as H, aj as ie, ak as se, al as ae, am as ce, K as F, O as ue, Z as Se, N as Ie, P as R, q as k, s as W, C as B, v as de, an as _e, ao as Re } from "./utils-yMopjW74.js";
async function Oe(t) {
  return new Promise((e) => setTimeout(e, t));
}
const J = 256;
let L = J, M;
function je(t = 11) {
  if (!M || L + t > J * 2) {
    M = "", L = 0;
    for (let e = 0; e < J; e++)
      M += (256 + Math.random() * 256 | 0).toString(16).substring(1);
  }
  return M.substring(L, L++ + t);
}
function Te(t) {
  const { batch: e, cacheTime: s = t.pollingInterval ?? 4e3, key: a = "base", name: o = "Base Client", pollingInterval: n = 4e3, type: u = "base" } = t, r = t.chain, c = t.account ? Pe(t.account) : void 0, { config: i, request: f, value: p } = t.transport({
    chain: r,
    pollingInterval: n
  }), y = { ...i, ...p }, w = {
    account: c,
    batch: e,
    cacheTime: s,
    chain: r,
    key: a,
    name: o,
    pollingInterval: n,
    request: f,
    transport: y,
    type: u,
    uid: je()
  };
  function m(g) {
    return (h) => {
      const b = h(g);
      for (const l in w)
        delete b[l];
      const d = { ...g, ...b };
      return Object.assign(d, { extend: m(d) });
    };
  }
  return Object.assign(w, { extend: m(w) });
}
function we(t, { delay: e = 100, retryCount: s = 2, shouldRetry: a = () => !0 } = {}) {
  return new Promise((o, n) => {
    const u = async ({ count: r = 0 } = {}) => {
      const c = async ({ error: i }) => {
        const f = typeof e == "function" ? e({ count: r, error: i }) : e;
        f && await Oe(f), u({ count: r + 1 });
      };
      try {
        const i = await t();
        o(i);
      } catch (i) {
        if (r < s && await a({ count: r, error: i }))
          return c({ error: i });
        n(i);
      }
    };
    u();
  });
}
function Ae(t, e = {}) {
  return async (s, a = {}) => {
    const { retryDelay: o = 150, retryCount: n = 3 } = {
      ...e,
      ...a
    };
    return we(async () => {
      try {
        return await t(s);
      } catch (u) {
        const r = u;
        switch (r.code) {
          case ce.code:
            throw new ce(r);
          case ae.code:
            throw new ae(r);
          case se.code:
            throw new se(r);
          case ie.code:
            throw new ie(r);
          case H.code:
            throw new H(r);
          case oe.code:
            throw new oe(r);
          case re.code:
            throw new re(r);
          case A.code:
            throw new A(r);
          case ne.code:
            throw new ne(r);
          case te.code:
            throw new te(r);
          case x.code:
            throw new x(r);
          case ee.code:
            throw new ee(r);
          case S.code:
            throw new S(r);
          case V.code:
            throw new V(r);
          case Y.code:
            throw new Y(r);
          case X.code:
            throw new X(r);
          case Q.code:
            throw new Q(r);
          case z.code:
            throw new z(r);
          case 5e3:
            throw new S(r);
          default:
            throw u instanceof ge ? u : new Ee(r);
        }
      }
    }, {
      delay: ({ count: u, error: r }) => {
        if (r && r instanceof T) {
          const c = r?.headers?.get("Retry-After");
          if (c?.match(/\d/))
            return parseInt(c) * 1e3;
        }
        return ~~(1 << u) * o;
      },
      retryCount: n,
      shouldRetry: ({ error: u }) => qe(u)
    });
  };
}
function qe(t) {
  return "code" in t && typeof t.code == "number" ? t.code === -1 || t.code === x.code || t.code === H.code : t instanceof T && t.status ? t.status === 403 || t.status === 408 || t.status === 413 || t.status === 429 || t.status === 500 || t.status === 502 || t.status === 503 || t.status === 504 : !0;
}
function ke({ key: t, name: e, request: s, retryCount: a = 3, retryDelay: o = 150, timeout: n, type: u }, r) {
  return {
    config: { key: t, name: e, request: s, retryCount: a, retryDelay: o, timeout: n, type: u },
    request: Ae(s, { retryCount: a, retryDelay: o }),
    value: r
  };
}
class Le extends ge {
  constructor() {
    super("No URL was provided to the Transport. Please provide a valid RPC URL to the Transport.", {
      docsPath: "/docs/clients/intro"
    });
  }
}
function ve(t, { errorInstance: e = new Error("timed out"), timeout: s, signal: a }) {
  return new Promise((o, n) => {
    (async () => {
      let u;
      try {
        const r = new AbortController();
        s > 0 && (u = setTimeout(() => {
          a ? r.abort() : n(e);
        }, s)), o(await t({ signal: r?.signal }));
      } catch (r) {
        r.name === "AbortError" && n(e), n(r);
      } finally {
        clearTimeout(u);
      }
    })();
  });
}
function Me() {
  return {
    current: 0,
    take() {
      return this.current++;
    },
    reset() {
      this.current = 0;
    }
  };
}
const le = /* @__PURE__ */ Me();
function De(t, e = {}) {
  return {
    async request(s) {
      const { body: a, fetchOptions: o = {}, timeout: n = e.timeout ?? 1e4 } = s, { headers: u, method: r, signal: c } = { ...e.fetchOptions, ...o };
      try {
        const i = await ve(async ({ signal: p }) => await fetch(t, {
          ...o,
          body: Array.isArray(a) ? F(a.map((w) => ({
            jsonrpc: "2.0",
            id: w.id ?? le.take(),
            ...w
          }))) : F({
            jsonrpc: "2.0",
            id: a.id ?? le.take(),
            ...a
          }),
          headers: {
            ...u,
            "Content-Type": "application/json"
          },
          method: r || "POST",
          signal: c || (n > 0 ? p : void 0)
        }), {
          errorInstance: new ue({ body: a, url: t }),
          timeout: n,
          signal: !0
        });
        let f;
        if (i.headers.get("Content-Type")?.startsWith("application/json") ? f = await i.json() : f = await i.text(), !i.ok)
          throw new T({
            body: a,
            details: F(f.error) || i.statusText,
            headers: i.headers,
            status: i.status,
            url: t
          });
        return f;
      } catch (i) {
        throw i instanceof T || i instanceof ue ? i : new T({
          body: a,
          details: i.message,
          url: t
        });
      }
    }
  };
}
function st(t, e = {}) {
  const { batch: s, fetchOptions: a, key: o = "http", name: n = "HTTP JSON-RPC", retryDelay: u } = e;
  return ({ chain: r, retryCount: c, timeout: i }) => {
    const { batchSize: f = 1e3, wait: p = 0 } = typeof s == "object" ? s : {}, y = e.retryCount ?? c, w = i ?? e.timeout ?? 1e4, m = t || r?.rpcUrls.default.http[0];
    if (!m)
      throw new Le();
    const g = De(m, { fetchOptions: a, timeout: w });
    return ke({
      key: o,
      name: n,
      async request({ method: h, params: b }) {
        const d = { method: h, params: b }, { schedule: l } = Ie({
          id: `${t}`,
          wait: p,
          shouldSplitBatch(E) {
            return E.length > f;
          },
          fn: (E) => g.request({
            body: E
          }),
          sort: (E, O) => E.id - O.id
        }), v = async (E) => s ? l(E) : [
          await g.request({
            body: E
          })
        ], [{ error: C, result: P }] = await v(d);
        if (C)
          throw new Se({
            body: d,
            error: C,
            url: m
          });
        return P;
      },
      retryCount: y,
      retryDelay: u,
      timeout: w,
      type: "http"
    }, {
      fetchOptions: a,
      url: m
    });
  };
}
function $e(t) {
  const e = (s) => t(s.detail);
  return window.addEventListener("eip6963:announceProvider", e), window.dispatchEvent(new CustomEvent("eip6963:requestProvider")), () => window.removeEventListener("eip6963:announceProvider", e);
}
function Ne() {
  const t = /* @__PURE__ */ new Set();
  let e = [];
  const s = () => $e((o) => {
    e.some(({ info: n }) => n.uuid === o.info.uuid) || (e = [...e, o], t.forEach((n) => n(e, { added: [o] })));
  });
  let a = s();
  return {
    _listeners() {
      return t;
    },
    clear() {
      t.forEach((o) => o([], { removed: [...e] })), e = [];
    },
    destroy() {
      this.clear(), t.clear(), a();
    },
    findProvider({ rdns: o }) {
      return e.find((n) => n.info.rdns === o);
    },
    getProviders() {
      return e;
    },
    reset() {
      this.clear(), a(), a = s();
    },
    subscribe(o, { emitImmediately: n } = {}) {
      return t.add(o), n && o(e, { added: e }), () => t.delete(o);
    }
  };
}
var ze = { VITE_WALLETCONNECT_PROJECT_ID: "7a4f8ad947d7cd10dfafdc64c69a267d", BASE_URL: "/", MODE: "production", DEV: !1, PROD: !0, SSR: !1 };
const Ue = (t) => (e, s, a) => {
  const o = a.subscribe;
  return a.subscribe = (u, r, c) => {
    let i = u;
    if (r) {
      const f = c?.equalityFn || Object.is;
      let p = u(a.getState());
      i = (y) => {
        const w = u(y);
        if (!f(p, w)) {
          const m = p;
          r(p = w, m);
        }
      }, c?.fireImmediately && r(p, p);
    }
    return o(i);
  }, t(e, s, a);
}, Fe = Ue;
function We(t, e) {
  let s;
  try {
    s = t();
  } catch {
    return;
  }
  return {
    getItem: (o) => {
      var n;
      const u = (c) => c === null ? null : JSON.parse(c, e?.reviver), r = (n = s.getItem(o)) != null ? n : null;
      return r instanceof Promise ? r.then(u) : u(r);
    },
    setItem: (o, n) => s.setItem(
      o,
      JSON.stringify(n, e?.replacer)
    ),
    removeItem: (o) => s.removeItem(o)
  };
}
const q = (t) => (e) => {
  try {
    const s = t(e);
    return s instanceof Promise ? s : {
      then(a) {
        return q(a)(s);
      },
      catch(a) {
        return this;
      }
    };
  } catch (s) {
    return {
      then(a) {
        return this;
      },
      catch(a) {
        return q(a)(s);
      }
    };
  }
}, xe = (t, e) => (s, a, o) => {
  let n = {
    getStorage: () => localStorage,
    serialize: JSON.stringify,
    deserialize: JSON.parse,
    partialize: (h) => h,
    version: 0,
    merge: (h, b) => ({
      ...b,
      ...h
    }),
    ...e
  }, u = !1;
  const r = /* @__PURE__ */ new Set(), c = /* @__PURE__ */ new Set();
  let i;
  try {
    i = n.getStorage();
  } catch {
  }
  if (!i)
    return t(
      (...h) => {
        console.warn(
          `[zustand persist middleware] Unable to update item '${n.name}', the given storage is currently unavailable.`
        ), s(...h);
      },
      a,
      o
    );
  const f = q(n.serialize), p = () => {
    const h = n.partialize({ ...a() });
    let b;
    const d = f({ state: h, version: n.version }).then(
      (l) => i.setItem(n.name, l)
    ).catch((l) => {
      b = l;
    });
    if (b)
      throw b;
    return d;
  }, y = o.setState;
  o.setState = (h, b) => {
    y(h, b), p();
  };
  const w = t(
    (...h) => {
      s(...h), p();
    },
    a,
    o
  );
  let m;
  const g = () => {
    var h;
    if (!i)
      return;
    u = !1, r.forEach((d) => d(a()));
    const b = ((h = n.onRehydrateStorage) == null ? void 0 : h.call(n, a())) || void 0;
    return q(i.getItem.bind(i))(n.name).then((d) => {
      if (d)
        return n.deserialize(d);
    }).then((d) => {
      if (d)
        if (typeof d.version == "number" && d.version !== n.version) {
          if (n.migrate)
            return n.migrate(
              d.state,
              d.version
            );
          console.error(
            "State loaded from storage couldn't be migrated since no migrate function was provided"
          );
        } else
          return d.state;
    }).then((d) => {
      var l;
      return m = n.merge(
        d,
        (l = a()) != null ? l : w
      ), s(m, !0), p();
    }).then(() => {
      b?.(m, void 0), u = !0, c.forEach((d) => d(m));
    }).catch((d) => {
      b?.(void 0, d);
    });
  };
  return o.persist = {
    setOptions: (h) => {
      n = {
        ...n,
        ...h
      }, h.getStorage && (i = h.getStorage());
    },
    clearStorage: () => {
      i?.removeItem(n.name);
    },
    getOptions: () => n,
    rehydrate: () => g(),
    hasHydrated: () => u,
    onHydrate: (h) => (r.add(h), () => {
      r.delete(h);
    }),
    onFinishHydration: (h) => (c.add(h), () => {
      c.delete(h);
    })
  }, g(), m || w;
}, He = (t, e) => (s, a, o) => {
  let n = {
    storage: We(() => localStorage),
    partialize: (g) => g,
    version: 0,
    merge: (g, h) => ({
      ...h,
      ...g
    }),
    ...e
  }, u = !1;
  const r = /* @__PURE__ */ new Set(), c = /* @__PURE__ */ new Set();
  let i = n.storage;
  if (!i)
    return t(
      (...g) => {
        console.warn(
          `[zustand persist middleware] Unable to update item '${n.name}', the given storage is currently unavailable.`
        ), s(...g);
      },
      a,
      o
    );
  const f = () => {
    const g = n.partialize({ ...a() });
    return i.setItem(n.name, {
      state: g,
      version: n.version
    });
  }, p = o.setState;
  o.setState = (g, h) => {
    p(g, h), f();
  };
  const y = t(
    (...g) => {
      s(...g), f();
    },
    a,
    o
  );
  let w;
  const m = () => {
    var g, h;
    if (!i)
      return;
    u = !1, r.forEach((d) => {
      var l;
      return d((l = a()) != null ? l : y);
    });
    const b = ((h = n.onRehydrateStorage) == null ? void 0 : h.call(n, (g = a()) != null ? g : y)) || void 0;
    return q(i.getItem.bind(i))(n.name).then((d) => {
      if (d)
        if (typeof d.version == "number" && d.version !== n.version) {
          if (n.migrate)
            return n.migrate(
              d.state,
              d.version
            );
          console.error(
            "State loaded from storage couldn't be migrated since no migrate function was provided"
          );
        } else
          return d.state;
    }).then((d) => {
      var l;
      return w = n.merge(
        d,
        (l = a()) != null ? l : y
      ), s(w, !0), f();
    }).then(() => {
      b?.(w, void 0), w = a(), u = !0, c.forEach((d) => d(w));
    }).catch((d) => {
      b?.(void 0, d);
    });
  };
  return o.persist = {
    setOptions: (g) => {
      n = {
        ...n,
        ...g
      }, g.storage && (i = g.storage);
    },
    clearStorage: () => {
      i?.removeItem(n.name);
    },
    getOptions: () => n,
    rehydrate: () => m(),
    hasHydrated: () => u,
    onHydrate: (g) => (r.add(g), () => {
      r.delete(g);
    }),
    onFinishHydration: (g) => (c.add(g), () => {
      c.delete(g);
    })
  }, n.skipHydration || m(), w || y;
}, Be = (t, e) => "getStorage" in e || "serialize" in e || "deserialize" in e ? ((ze ? "production" : void 0) !== "production" && console.warn(
  "[DEPRECATED] `getStorage`, `serialize` and `deserialize` options are deprecated. Use `storage` option instead."
), xe(t, e)) : He(t, e), Je = Be;
var Ke = { VITE_WALLETCONNECT_PROJECT_ID: "7a4f8ad947d7cd10dfafdc64c69a267d", BASE_URL: "/", MODE: "production", DEV: !1, PROD: !0, SSR: !1 };
const fe = (t) => {
  let e;
  const s = /* @__PURE__ */ new Set(), a = (c, i) => {
    const f = typeof c == "function" ? c(e) : c;
    if (!Object.is(f, e)) {
      const p = e;
      e = i ?? typeof f != "object" ? f : Object.assign({}, e, f), s.forEach((y) => y(e, p));
    }
  }, o = () => e, r = { setState: a, getState: o, subscribe: (c) => (s.add(c), () => s.delete(c)), destroy: () => {
    (Ke ? "production" : void 0) !== "production" && console.warn(
      "[DEPRECATED] The `destroy` method will be unsupported in a future version. Instead use unsubscribe function returned by subscribe. Everything will be garbage-collected if store is garbage-collected."
    ), s.clear();
  } };
  return e = t(a, o, r), r;
}, he = (t) => t ? fe(t) : fe;
var at = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function Ge(t) {
  return t && t.__esModule && Object.prototype.hasOwnProperty.call(t, "default") ? t.default : t;
}
function ct(t) {
  if (t.__esModule)
    return t;
  var e = t.default;
  if (typeof e == "function") {
    var s = function a() {
      return this instanceof a ? Reflect.construct(e, arguments, this.constructor) : e.apply(this, arguments);
    };
    s.prototype = e.prototype;
  } else
    s = {};
  return Object.defineProperty(s, "__esModule", { value: !0 }), Object.keys(t).forEach(function(a) {
    var o = Object.getOwnPropertyDescriptor(t, a);
    Object.defineProperty(s, a, o.get ? o : {
      enumerable: !0,
      get: function() {
        return t[a];
      }
    });
  }), s;
}
var ye = { exports: {} };
(function(t) {
  var e = Object.prototype.hasOwnProperty, s = "~";
  function a() {
  }
  Object.create && (a.prototype = /* @__PURE__ */ Object.create(null), new a().__proto__ || (s = !1));
  function o(c, i, f) {
    this.fn = c, this.context = i, this.once = f || !1;
  }
  function n(c, i, f, p, y) {
    if (typeof f != "function")
      throw new TypeError("The listener must be a function");
    var w = new o(f, p || c, y), m = s ? s + i : i;
    return c._events[m] ? c._events[m].fn ? c._events[m] = [c._events[m], w] : c._events[m].push(w) : (c._events[m] = w, c._eventsCount++), c;
  }
  function u(c, i) {
    --c._eventsCount === 0 ? c._events = new a() : delete c._events[i];
  }
  function r() {
    this._events = new a(), this._eventsCount = 0;
  }
  r.prototype.eventNames = function() {
    var i = [], f, p;
    if (this._eventsCount === 0)
      return i;
    for (p in f = this._events)
      e.call(f, p) && i.push(s ? p.slice(1) : p);
    return Object.getOwnPropertySymbols ? i.concat(Object.getOwnPropertySymbols(f)) : i;
  }, r.prototype.listeners = function(i) {
    var f = s ? s + i : i, p = this._events[f];
    if (!p)
      return [];
    if (p.fn)
      return [p.fn];
    for (var y = 0, w = p.length, m = new Array(w); y < w; y++)
      m[y] = p[y].fn;
    return m;
  }, r.prototype.listenerCount = function(i) {
    var f = s ? s + i : i, p = this._events[f];
    return p ? p.fn ? 1 : p.length : 0;
  }, r.prototype.emit = function(i, f, p, y, w, m) {
    var g = s ? s + i : i;
    if (!this._events[g])
      return !1;
    var h = this._events[g], b = arguments.length, d, l;
    if (h.fn) {
      switch (h.once && this.removeListener(i, h.fn, void 0, !0), b) {
        case 1:
          return h.fn.call(h.context), !0;
        case 2:
          return h.fn.call(h.context, f), !0;
        case 3:
          return h.fn.call(h.context, f, p), !0;
        case 4:
          return h.fn.call(h.context, f, p, y), !0;
        case 5:
          return h.fn.call(h.context, f, p, y, w), !0;
        case 6:
          return h.fn.call(h.context, f, p, y, w, m), !0;
      }
      for (l = 1, d = new Array(b - 1); l < b; l++)
        d[l - 1] = arguments[l];
      h.fn.apply(h.context, d);
    } else {
      var v = h.length, C;
      for (l = 0; l < v; l++)
        switch (h[l].once && this.removeListener(i, h[l].fn, void 0, !0), b) {
          case 1:
            h[l].fn.call(h[l].context);
            break;
          case 2:
            h[l].fn.call(h[l].context, f);
            break;
          case 3:
            h[l].fn.call(h[l].context, f, p);
            break;
          case 4:
            h[l].fn.call(h[l].context, f, p, y);
            break;
          default:
            if (!d)
              for (C = 1, d = new Array(b - 1); C < b; C++)
                d[C - 1] = arguments[C];
            h[l].fn.apply(h[l].context, d);
        }
    }
    return !0;
  }, r.prototype.on = function(i, f, p) {
    return n(this, i, f, p, !1);
  }, r.prototype.once = function(i, f, p) {
    return n(this, i, f, p, !0);
  }, r.prototype.removeListener = function(i, f, p, y) {
    var w = s ? s + i : i;
    if (!this._events[w])
      return this;
    if (!f)
      return u(this, w), this;
    var m = this._events[w];
    if (m.fn)
      m.fn === f && (!y || m.once) && (!p || m.context === p) && u(this, w);
    else {
      for (var g = 0, h = [], b = m.length; g < b; g++)
        (m[g].fn !== f || y && !m[g].once || p && m[g].context !== p) && h.push(m[g]);
      h.length ? this._events[w] = h.length === 1 ? h[0] : h : u(this, w);
    }
    return this;
  }, r.prototype.removeAllListeners = function(i) {
    var f;
    return i ? (f = s ? s + i : i, this._events[f] && u(this, f)) : (this._events = new a(), this._eventsCount = 0), this;
  }, r.prototype.off = r.prototype.removeListener, r.prototype.addListener = r.prototype.on, r.prefixed = s, r.EventEmitter = r, t.exports = r;
})(ye);
var Ze = ye.exports;
const Qe = /* @__PURE__ */ Ge(Ze);
var j = function(t, e, s, a) {
  if (s === "a" && !a)
    throw new TypeError("Private accessor was defined without a getter");
  if (typeof e == "function" ? t !== e || !a : !e.has(t))
    throw new TypeError("Cannot read private member from an object whose class did not declare it");
  return s === "m" ? a : s === "a" ? a.call(t) : a ? a.value : e.get(t);
}, _;
class Xe {
  constructor(e) {
    Object.defineProperty(this, "uid", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: e
    }), _.set(this, new Qe());
  }
  on(e, s) {
    j(this, _, "f").on(e, s);
  }
  once(e, s) {
    j(this, _, "f").once(e, s);
  }
  off(e, s) {
    j(this, _, "f").off(e, s);
  }
  emit(e, ...s) {
    const a = s[0];
    j(this, _, "f").emit(e, { uid: this.uid, ...a });
  }
  listenerCount(e) {
    return j(this, _, "f").listenerCount(e);
  }
}
_ = /* @__PURE__ */ new WeakMap();
function Ye(t) {
  return new Xe(t);
}
function Ve(t, e) {
  return JSON.parse(t, (s, a) => {
    let o = a;
    return o?.__type === "bigint" && (o = BigInt(o.value)), o?.__type === "Map" && (o = new Map(o.value)), e?.(s, o) ?? o;
  });
}
function pe(t, e) {
  return t.slice(0, e).join(".") || ".";
}
function me(t, e) {
  const { length: s } = t;
  for (let a = 0; a < s; ++a)
    if (t[a] === e)
      return a + 1;
  return 0;
}
function et(t, e) {
  const s = typeof t == "function", a = typeof e == "function", o = [], n = [];
  return function(r, c) {
    if (typeof c == "object")
      if (o.length) {
        const i = me(o, this);
        i === 0 ? o[o.length] = this : (o.splice(i), n.splice(i)), n[n.length] = r;
        const f = me(o, c);
        if (f !== 0)
          return a ? e.call(this, r, c, pe(n, f)) : `[ref=${pe(n, f)}]`;
      } else
        o[0] = c, n[0] = r;
    return s ? t.call(this, r, c) : c;
  };
}
function tt(t, e, s, a) {
  return JSON.stringify(t, et((o, n) => {
    let u = n;
    return typeof u == "bigint" && (u = { __type: "bigint", value: n.toString() }), u instanceof Map && (u = { __type: "Map", value: Array.from(n.entries()) }), e?.(o, u) ?? u;
  }, a), s ?? void 0);
}
function nt(t) {
  const { deserialize: e = Ve, key: s = "wagmi", serialize: a = tt, storage: o = be } = t;
  function n(u) {
    return u instanceof Promise ? u.then((r) => r).catch(() => null) : u;
  }
  return {
    ...o,
    key: s,
    async getItem(u, r) {
      const c = o.getItem(`${s}.${u}`), i = await n(c);
      return i ? e(i) ?? null : r ?? null;
    },
    async setItem(u, r) {
      const c = `${s}.${u}`;
      r === null ? await n(o.removeItem(c)) : await n(o.setItem(c, a(r)));
    },
    async removeItem(u) {
      await n(o.removeItem(`${s}.${u}`));
    }
  };
}
const be = {
  getItem: () => null,
  setItem: () => {
  },
  removeItem: () => {
  }
};
function ut(t) {
  return t;
}
const rt = {
  coinbaseWallet: {
    id: "coinbaseWallet",
    name: "Coinbase Wallet",
    provider(t) {
      return t?.coinbaseWalletExtension ? t.coinbaseWalletExtension : N(t, "isCoinbaseWallet");
    }
  },
  metaMask: {
    id: "metaMask",
    name: "MetaMask",
    provider(t) {
      return N(t, (e) => {
        if (!e.isMetaMask || e.isBraveWallet && !e._events && !e._state)
          return !1;
        const s = [
          "isApexWallet",
          "isAvalanche",
          "isBitKeep",
          "isBlockWallet",
          "isKuCoinWallet",
          "isMathWallet",
          "isOkxWallet",
          "isOKExWallet",
          "isOneInchIOSWallet",
          "isOneInchAndroidWallet",
          "isOpera",
          "isPortal",
          "isRabby",
          "isTokenPocket",
          "isTokenary",
          "isZerion"
        ];
        for (const a of s)
          if (e[a])
            return !1;
        return !0;
      });
    }
  },
  phantom: {
    id: "phantom",
    name: "Phantom",
    provider(t) {
      return t?.phantom?.ethereum ? t.phantom?.ethereum : N(t, "isPhantom");
    }
  }
};
Z.type = "injected";
function Z(t = {}) {
  const { shimDisconnect: e = !0, unstable_shimAsyncInject: s } = t;
  function a() {
    const o = t.target;
    if (typeof o == "function") {
      const n = o();
      if (n)
        return n;
    }
    return typeof o == "object" ? o : typeof o == "string" ? {
      ...rt[o] ?? {
        id: o,
        name: `${o[0].toUpperCase()}${o.slice(1)}`,
        provider: `is${o[0].toUpperCase()}${o.slice(1)}`
      }
    } : {
      id: "injected",
      name: "Injected",
      provider(n) {
        return n?.ethereum;
      }
    };
  }
  return (o) => ({
    get icon() {
      return a().icon;
    },
    get id() {
      return a().id;
    },
    get name() {
      return a().name;
    },
    type: Z.type,
    async setup() {
      const n = await this.getProvider();
      n && t.target && n.on("connect", this.onConnect.bind(this));
    },
    async connect({ chainId: n, isReconnecting: u } = {}) {
      const r = await this.getProvider();
      if (!r)
        throw new R();
      let c = null;
      if (!u && (c = await this.getAccounts().catch(() => null), !!c?.length))
        try {
          c = (await r.request({
            method: "wallet_requestPermissions",
            params: [{ eth_accounts: {} }]
          }))[0]?.caveats?.[0]?.value?.map((p) => k(p));
        } catch (f) {
          const p = f;
          if (p.code === S.code)
            throw new S(p);
          if (p.code === A.code)
            throw p;
        }
      try {
        c?.length || (c = (await r.request({
          method: "eth_requestAccounts"
        })).map((p) => k(p))), r.removeListener("connect", this.onConnect.bind(this)), r.on("accountsChanged", this.onAccountsChanged.bind(this)), r.on("chainChanged", this.onChainChanged), r.on("disconnect", this.onDisconnect.bind(this));
        let i = await this.getChainId();
        return n && i !== n && (i = (await this.switchChain({ chainId: n }).catch((p) => {
          if (p.code === S.code)
            throw p;
          return { id: i };
        }))?.id ?? i), e && (await o.storage?.removeItem(`${this.id}.disconnected`), t.target || await o.storage?.setItem("injected.connected", !0)), { accounts: c, chainId: i };
      } catch (i) {
        const f = i;
        throw f.code === S.code ? new S(f) : f.code === A.code ? new A(f) : f;
      }
    },
    async disconnect() {
      const n = await this.getProvider();
      if (!n)
        throw new R();
      n.removeListener("accountsChanged", this.onAccountsChanged.bind(this)), n.removeListener("chainChanged", this.onChainChanged), n.removeListener("disconnect", this.onDisconnect.bind(this)), n.on("connect", this.onConnect.bind(this)), e && (await o.storage?.setItem(`${this.id}.disconnected`, !0), t.target || await o.storage?.removeItem("injected.connected"));
    },
    async getAccounts() {
      const n = await this.getProvider();
      if (!n)
        throw new R();
      return (await n.request({ method: "eth_accounts" })).map((r) => k(r));
    },
    async getChainId() {
      const n = await this.getProvider();
      if (!n)
        throw new R();
      const u = await n.request({ method: "eth_chainId" });
      return W(u);
    },
    async getProvider() {
      if (typeof window > "u")
        return;
      const n = a();
      return typeof n.provider == "function" ? n.provider(window) : typeof n.provider == "string" ? N(window, n.provider) : n.provider;
    },
    async isAuthorized() {
      try {
        if (e && // If shim exists in storage, connector is disconnected
        await o.storage?.getItem(`${this.id}.disconnected`) || !t.target && !await o.storage?.getItem("injected.connected"))
          return !1;
        if (!await this.getProvider()) {
          if (s !== void 0 && s !== !1) {
            const c = async () => (typeof window < "u" && window.removeEventListener("ethereum#initialized", c), !!await this.getProvider()), i = typeof s == "number" ? s : 1e3;
            if (await Promise.race([
              ...typeof window < "u" ? [
                new Promise((p) => window.addEventListener("ethereum#initialized", () => p(c()), { once: !0 }))
              ] : [],
              new Promise((p) => setTimeout(() => p(c()), i))
            ]))
              return !0;
          }
          throw new R();
        }
        return !!(await we(() => ve(() => this.getAccounts(), {
          timeout: 100
        }))).length;
      } catch {
        return !1;
      }
    },
    async switchChain({ chainId: n }) {
      const u = await this.getProvider();
      if (!u)
        throw new R();
      const r = o.chains.find((c) => c.id === n);
      if (!r)
        throw new z(new B());
      try {
        return await Promise.all([
          u.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: de(n) }]
          }),
          new Promise((c) => o.emitter.once("change", ({ chainId: i }) => {
            i === n && c();
          }))
        ]), r;
      } catch (c) {
        const i = c;
        if (i.code === 4902 || // Unwrapping for MetaMask Mobile
        // https://github.com/MetaMask/metamask-mobile/issues/2944#issuecomment-976988719
        i?.data?.originalError?.code === 4902)
          try {
            const { default: f, ...p } = r.blockExplorers ?? {};
            let y = [];
            if (f && (y = [
              f.url,
              ...Object.values(p).map((m) => m.url)
            ]), await u.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: de(n),
                  chainName: r.name,
                  nativeCurrency: r.nativeCurrency,
                  rpcUrls: [r.rpcUrls.default?.http[0] ?? ""],
                  blockExplorerUrls: y
                }
              ]
            }), await this.getChainId() !== n)
              throw new S(new Error("User rejected switch after adding network."));
            return r;
          } catch (f) {
            throw new S(f);
          }
        throw i.code === S.code ? new S(i) : new z(i);
      }
    },
    async onAccountsChanged(n) {
      if (n.length === 0)
        this.onDisconnect();
      else if (o.emitter.listenerCount("connect")) {
        const u = (await this.getChainId()).toString();
        this.onConnect({ chainId: u }), e && await o.storage?.removeItem(`${this.id}.disconnected`);
      } else
        o.emitter.emit("change", {
          accounts: n.map((u) => k(u))
        });
    },
    onChainChanged(n) {
      const u = W(n);
      o.emitter.emit("change", { chainId: u });
    },
    async onConnect(n) {
      const u = await this.getAccounts();
      if (u.length === 0)
        return;
      const r = W(n.chainId);
      o.emitter.emit("connect", { accounts: u, chainId: r });
      const c = await this.getProvider();
      c && (c.removeListener("connect", this.onConnect.bind(this)), c.on("accountsChanged", this.onAccountsChanged.bind(this)), c.on("chainChanged", this.onChainChanged), c.on("disconnect", this.onDisconnect.bind(this)));
    },
    async onDisconnect(n) {
      const u = await this.getProvider();
      n && n.code === 1013 && u && (await this.getAccounts()).length || (o.emitter.emit("disconnect"), u && (u.removeListener("accountsChanged", this.onAccountsChanged.bind(this)), u.removeListener("chainChanged", this.onChainChanged), u.removeListener("disconnect", this.onDisconnect.bind(this)), u.on("connect", this.onConnect.bind(this))));
    }
  });
}
function N(t, e) {
  function s(o) {
    return typeof e == "function" ? e(o) : typeof e == "string" ? o[e] : !0;
  }
  const a = t.ethereum;
  if (a?.providers)
    return a.providers.find((o) => s(o));
  if (a && s(a))
    return a;
}
const K = 256;
let D = K, $;
function ot(t = 11) {
  if (!$ || D + t > K * 2) {
    $ = "", D = 0;
    for (let e = 0; e < K; e++)
      $ += (256 + Math.random() * 256 | 0).toString(16).substring(1);
  }
  return $.substring(D, D++ + t);
}
function dt(t) {
  const { chains: e, multiInjectedProviderDiscovery: s = !0, storage: a = nt({
    storage: typeof window < "u" && window.localStorage ? window.localStorage : be
  }), syncConnectedChain: o = !0, ssr: n, ...u } = t, r = typeof window < "u" && s ? Ne() : void 0, c = he(() => [
    ...u.connectors ?? [],
    ...n ? [] : r?.getProviders().map(f) ?? []
  ].map(i));
  function i(d) {
    const l = Ye(ot()), v = {
      ...d({ emitter: l, chains: e, storage: a }),
      emitter: l,
      uid: l.uid
    };
    return l.on("connect", h), v.setup?.(), v;
  }
  function f(d) {
    const { info: l } = d, v = d.provider;
    return Z({ target: { ...l, id: l.rdns, provider: v } });
  }
  const p = /* @__PURE__ */ new Map();
  function y(d = {}) {
    const l = d.chainId ?? m.getState().chainId, v = e.find((P) => P.id === l);
    if (d.chainId && !v)
      throw new B();
    {
      const P = p.get(m.getState().chainId);
      if (P && !v)
        return P;
      if (!v)
        throw new B();
    }
    {
      const P = p.get(l);
      if (P)
        return P;
    }
    let C;
    if (u.client)
      C = u.client({ chain: v });
    else {
      const P = v.id, E = {}, O = Object.entries(u);
      for (const [I, U] of O)
        I === "client" || I === "connectors" || I === "transports" || (typeof U == "object" ? E[I] = U[P] : E[I] = U);
      C = Te({
        ...E,
        chain: v,
        batch: E.batch ?? { multicall: !0 },
        transport: (I) => u.transports[P]({ ...I, connectors: c })
      });
    }
    return p.set(l, C), C;
  }
  const w = {
    chainId: e[0].id,
    connections: /* @__PURE__ */ new Map(),
    current: void 0,
    status: "disconnected"
  }, m = he(Fe(
    // only use persist middleware if storage exists
    a ? Je(() => w, {
      name: "store",
      partialize(d) {
        return {
          connections: {
            __type: "Map",
            value: Array.from(d.connections.entries()).map(([l, v]) => {
              const { id: C, name: P, type: E, uid: O } = v.connector;
              return [l, { ...v, connector: { id: C, name: P, type: E, uid: O } }];
            })
          },
          chainId: d.chainId,
          current: d.current
        };
      },
      skipHydration: n,
      storage: a,
      version: 1
    }) : () => w
  ));
  o && m.subscribe(({ connections: d, current: l }) => l ? d.get(l)?.chainId : void 0, (d) => {
    if (e.some((v) => v.id === d))
      return m.setState((v) => ({
        ...v,
        chainId: d ?? v.chainId
      }));
  }), r?.subscribe((d) => {
    const l = /* @__PURE__ */ new Map();
    for (const C of c.getState())
      l.set(C.id, !0);
    const v = [];
    for (const C of d) {
      const P = i(f(C));
      l.has(P.id) || v.push(P);
    }
    c.setState((C) => [...C, ...v], !0);
  });
  function g(d) {
    m.setState((l) => {
      const v = l.connections.get(d.uid);
      return {
        ...l,
        connections: new Map(l.connections).set(d.uid, {
          accounts: d.accounts ?? v.accounts,
          chainId: d.chainId ?? v.chainId,
          connector: v.connector
        })
      };
    });
  }
  function h(d) {
    m.getState().status === "connecting" || m.getState().status === "reconnecting" || m.setState((l) => {
      const v = c.getState().find((C) => C.uid === d.uid);
      return v ? {
        ...l,
        connections: new Map(l.connections).set(d.uid, {
          accounts: d.accounts,
          chainId: d.chainId,
          connector: v
        }),
        current: d.uid,
        status: "connected"
      } : l;
    });
  }
  function b(d) {
    m.setState((l) => {
      const v = l.connections.get(d.uid);
      if (v && (v.connector.emitter.off("change", g), v.connector.emitter.off("disconnect", b), v.connector.emitter.on("connect", h)), l.connections.delete(d.uid), l.connections.size === 0)
        return {
          ...l,
          connections: /* @__PURE__ */ new Map(),
          current: void 0,
          status: "disconnected"
        };
      const C = l.connections.values().next().value;
      return {
        ...l,
        connections: new Map(l.connections),
        current: C.connector.uid
      };
    });
  }
  return {
    chains: e,
    get connectors() {
      return c.getState();
    },
    storage: a,
    getClient: y,
    get state() {
      return m.getState();
    },
    setState(d) {
      let l;
      typeof d == "function" ? l = d(m.getState()) : l = d, typeof l != "object" && (l = w), Object.keys(w).some((C) => !(C in l)) && (l = w), m.setState(l, !0);
    },
    subscribe(d, l, v) {
      return m.subscribe(d, l, v ? { ...v, fireImmediately: v.emitImmediately } : void 0);
    },
    _internal: {
      mipd: r,
      store: m,
      ssr: !!n,
      syncConnectedChain: o,
      transports: u.transports,
      connectors: {
        providerDetailToConnector: f,
        setup: i,
        setState: (d) => c.setState(typeof d == "function" ? d(c.getState()) : d, !0),
        subscribe: (d) => c.subscribe(d)
      },
      events: { change: g, connect: h, disconnect: b }
    }
  };
}
function lt(t) {
  return {
    onFetch: (e, s) => {
      const a = async () => {
        const o = e.options, n = e.fetchOptions?.meta?.fetchMore?.direction, u = e.state.data?.pages || [], r = e.state.data?.pageParams || [], c = { pages: [], pageParams: [] };
        let i = !1;
        const f = (m) => {
          Object.defineProperty(m, "signal", {
            enumerable: !0,
            get: () => (e.signal.aborted ? i = !0 : e.signal.addEventListener("abort", () => {
              i = !0;
            }), e.signal)
          });
        }, p = e.options.queryFn || (() => Promise.reject(
          new Error(`Missing queryFn: '${e.options.queryHash}'`)
        )), y = async (m, g, h) => {
          if (i)
            return Promise.reject();
          if (g == null && m.pages.length)
            return Promise.resolve(m);
          const b = {
            queryKey: e.queryKey,
            pageParam: g,
            direction: h ? "backward" : "forward",
            meta: e.options.meta
          };
          f(b);
          const d = await p(
            b
          ), { maxPages: l } = e.options, v = h ? _e : Re;
          return {
            pages: v(m.pages, d, l),
            pageParams: v(m.pageParams, g, l)
          };
        };
        let w;
        if (n && u.length) {
          const m = n === "backward", g = m ? Ce : G, h = {
            pages: u,
            pageParams: r
          }, b = g(o, h);
          w = await y(h, b, m);
        } else {
          w = await y(
            c,
            r[0] ?? o.initialPageParam
          );
          const m = t ?? u.length;
          for (let g = 1; g < m; g++) {
            const h = G(o, w);
            w = await y(w, h);
          }
        }
        return w;
      };
      e.options.persister ? e.fetchFn = () => e.options.persister?.(
        a,
        {
          queryKey: e.queryKey,
          meta: e.options.meta,
          signal: e.signal
        },
        s
      ) : e.fetchFn = a;
    }
  };
}
function G(t, { pages: e, pageParams: s }) {
  const a = e.length - 1;
  return t.getNextPageParam(
    e[a],
    e,
    s[a],
    s
  );
}
function Ce(t, { pages: e, pageParams: s }) {
  return t.getPreviousPageParam?.(
    e[0],
    e,
    s[0],
    s
  );
}
function ft(t, e) {
  return e ? G(t, e) != null : !1;
}
function ht(t, e) {
  return !e || !t.getPreviousPageParam ? !1 : Ce(t, e) != null;
}
export {
  Qe as O,
  Le as U,
  Ge as a,
  ut as b,
  at as c,
  Z as d,
  Ze as e,
  dt as f,
  ct as g,
  st as h,
  lt as i,
  ke as j,
  le as k,
  ve as l,
  we as m,
  Te as n,
  Ve as o,
  ft as p,
  ht as q,
  nt as r,
  be as s,
  tt as t,
  Oe as w
};
