import { h } from 'vue';
import { createRouter, createWebHistory, RouterView } from 'vue-router';

import MainView from '../views/MainView.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'main',
      component: MainView,
      children: [
        {
          path: '/',
          name: 'pre-order',
          components: {
            default: () => import('../views/PreOrder/PreOrderPage.vue'),
            header: () => import('../layouts/Header/PreOrderHeader.vue'),
          },
          children: [
            {
              path: 'sell',
              name: 'sell',
              component: () => import('../views/Sell/SellPreOrderPage.vue'),
            },
            {
              path: 'buy',
              name: 'buy',
              component: () => import('../views/Buy/BuyPreOrderPage.vue'),
            },
          ],
        },
        {
          path: 'auth',
          name: 'auth',
          components: {
            default: () => import('../views/Auth/AuthPage.vue'),
            header: () => import('../layouts/Header/DefaultHeader.vue'),
          },
          props: {
            header: {
              title: 'Enter your email',
              canBack: true,
            },
          },
        },
        {
          path: 'auth/pin/:email',
          name: 'auth-pin',
          components: {
            default: () => import('../views/Auth/AuthPinPage.vue'),
            header: () => import('../layouts/Header/DefaultHeader.vue'),
          },
          props: {
            header: {
              title: 'Check your email',
              canBack: true,
            },
          },
        },
        // TODO: Find a better way. Mby like modal?
        {
          path: 'auth/business-not-available',
          name: 'auth-business-not-available',
          components: {
            default: () => import('../views/Auth/AuthBusinessNotAvailablePage.vue'),
            header: () => import('../layouts/Header/DefaultHeader.vue'),
          },
          props: {
            header: {
              title: 'Not available yet',
              hasMenu: false,
            },
          },
        },
        {
          path: 'sell/order',
          name: 'sell-order',
          components: {
            default: () => import('../views/Sell/SellOrderPage.vue'),
            header: () => import('../layouts/Header/DefaultHeader.vue'),
          },
          props: {
            header: {
              title: 'Your order',
            },
          },
        },
        {
          path: 'buy/order',
          name: 'buy-order',
          components: {
            default: () => import('../views/Buy/BuyOrderPage.vue'),
            header: () => import('../layouts/Header/DefaultHeader.vue'),
          },
          props: {
            header: {
              title: 'Your order',
            },
          },
        },
        {
          path: 'buy/transaction/:id',
          name: 'buy-transaction-details',
          components: {
            default: () => import('../views/Transaction/BuyTransactionDetailsPage.vue'),
            header: () => import('../layouts/Header/TransactionDetailsHeader.vue'),
          },
        },
        {
          path: 'buy/not-available',
          name: 'buy-not-available',
          components: {
            default: () => import('../views/Buy/BuyNotAvailablePage.vue'),
            header: () => import('../layouts/Header/DefaultHeader.vue'),
          },
          props: {
            header: {
              title: 'Buy feature is not available',
            },
          },
        },
        {
          path: 'sell/transaction/:id',
          name: 'sell-transaction-details',
          components: {
            default: () => import('../views/Transaction/SellTransactionDetailsPage.vue'),
            header: () => import('../layouts/Header/TransactionDetailsHeader.vue'),
          },
        },
        {
          path: 'verification',
          name: 'verification',
          components: {
            default: () => import('../views/Verification/VerificationPage.vue'),
            header: {
              render() {
                return h(RouterView, { name: 'verificationHeader' });
              },
            },
          },
          children: [
            {
              path: 'welcome',
              name: 'verification-welcome',
              components: {
                default: () => import('../views/Verification/VerificationWelcomePage.vue'),
                verificationHeader: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                verificationHeader: {
                  title: 'Welcome to Swapin',
                  canBack: true,
                },
              },
            },
            {
              path: 'details',
              name: 'verification-details',
              components: {
                default: () => import('../views/Verification/VerificationDetailsPage.vue'),
                verificationHeader: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                verificationHeader: {
                  title: 'Personal details',
                  canBack: true,
                },
              },
            },
            {
              path: 'address',
              name: 'verification-address',
              components: {
                default: () => import('../views/Verification/VerificationAddressPage.vue'),
                verificationHeader: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                verificationHeader: {
                  title: 'Residential address',
                  canBack: true,
                },
              },
            },
            {
              path: 'identity',
              name: 'verification-identity',
              components: {
                default: () => import('../views/Verification/VerificationIdentityPage.vue'),
                verificationHeader: () => import('../layouts/Header/VerificationIdentityHeader.vue'),
              },
              props: {
                verificationHeader: {
                  title: 'Your identity',
                  canBack: true,
                },
              },
            },
            {
              path: 'country-mismatch',
              name: 'verification-country-mismatch',
              components: {
                default: () => import('../views/Verification/VerificationCountryMismatchPage.vue'),
                verificationHeader: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                verificationHeader: {
                  title: 'Your identity',
                },
              },
            },
            {
              path: 'poa',
              name: 'verification-poa',
              components: {
                default: () => import('../views/Verification/VerificationPoaPage.vue'),
                verificationHeader: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                verificationHeader: {
                  title: 'Verify your address',
                  canBack: true,
                },
              },
            },
            {
              path: 'financial',
              name: 'verification-financial',
              components: {
                default: () => import('../views/Verification/VerificationFinancialPage.vue'),
                verificationHeader: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                verificationHeader: {
                  title: 'Financial info',
                  canBack: true,
                },
              },
            },
            {
              path: 'sof',
              name: 'verification-sof',
              components: {
                default: () => import('../views/Verification/VerificationSofPage.vue'),
                verificationHeader: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                verificationHeader: {
                  title: 'Financial info',
                  canBack: true,
                },
              },
            },
            {
              path: 'confirmation',
              name: 'verification-confirmation',
              components: {
                default: () => import('../views/Verification/VerificationConfirmationPage.vue'),
                verificationHeader: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                verificationHeader: {
                  title: 'Confirm terms',
                  canBack: true,
                },
              },
            },
            {
              path: 'setting-up-account',
              name: 'verification-setting-up-account',
              components: {
                default: () => import('../views/Verification/VerificationSettingUpAccountPage.vue'),
                verificationHeader: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                verificationHeader: {
                  title: 'Setting up your account',
                },
              },
            },
            {
              path: 'review',
              name: 'verification-review',
              components: {
                default: () => import('../views/Verification/VerificationOnReviewPage.vue'),
                verificationHeader: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                verificationHeader: {
                  title: 'Thank you',
                },
              },
            },
            {
              path: 'complete',
              name: 'verification-complete',
              components: {
                default: () => import('../views/Verification/VerificationCompletePage.vue'),
                verificationHeader: () => import('../layouts/Header/DefaultHeader.vue'),
              },
            },
            {
              path: 'no-service',
              name: 'verification-no-service',
              components: {
                default: () => import('../views/Verification/VerificationNoServicePage.vue'),
                verificationHeader: () => import('../layouts/Header/DefaultHeader.vue'),
              },
            },
          ],
        },
        {
          path: 'profile',
          name: 'profile',
          components: {
            default: () => import('../views/Profile/ProfilePage.vue'),
            header: () => import('../layouts/Header/DefaultHeader.vue'),
          },
          props: {
            header: {
              title: 'My profile',
              isTitleLeft: true,
            },
          },
        },
        {
          path: 'limits',
          name: 'limits',
          components: {
            default: () => import('../views/Limits/LimitsPage.vue'),
            header: () => import('../layouts/Header/DefaultHeader.vue'),
          },
          props: {
            header: {
              title: 'My limits',
              isTitleLeft: true,
            },
          },
        },
        {
          path: 'terms',
          name: 'terms',
          components: {
            default: () => import('../views/Terms/TermsPage.vue'),
            header: () => import('../layouts/Header/DefaultHeader.vue'),
          },
          props: {
            header: {
              title: 'Terms and conditions',
              isTitleLeft: true,
            },
          },
        },
        {
          path: 'support',
          name: 'support',
          components: {
            default: () => import('../views/Support/SupportPage.vue'),
            header: () => import('../layouts/Header/DefaultHeader.vue'),
          },
          props: {
            header: {
              title: 'Support',
              isTitleLeft: true,
            },
          },
        },
        {
          path: 'transactions',
          name: 'transaction-history',
          components: {
            default: () => import('../views/TransactionHistory/TransactionHistoryPage.vue'),
            header: () => import('../layouts/Header/DefaultHeader.vue'),
          },
          props: {
            header: {
              title: 'Transaction history',
              isTitleLeft: true,
            },
          },
        },
      ],
    },
    {
      path: '/not-available',
      name: 'not-available',
      component: () => import('../views/Errors/NotAvailablePage.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import('../views/Errors/NotFoundPage.vue'),
    },
  ],
});

export default router;
