<template>
  <div class="container-wrapper">
    <div class="container">
      <div class="container__content">
        <RouterView
          name="header"
          class="container__content-header"
        />

        <div class="container__content-view scroll-restore-element">
          <RouterView />
        </div>
      </div>
    </div>
    <div class="d-none d-sm-block my-6">
      <span class="fs-xs">Powered by</span>
      <img
        src="@images/logo.svg"
        alt="logo"
        height="24"
        class="ms-2"
      >
    </div>
  </div>
</template>

<script>
import { RouterView } from 'vue-router';

export default {
  components: {
    RouterView,
  },

  mounted() {
    document.querySelector('html').classList.add('app--ready');
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 500px;
  max-width: 100%;
  height: 630px;
  padding: 0;
  background-color: var(--bs-white);
  border-radius: 0.5rem;
  box-shadow: 0 228px 91px rgb(41 41 46 / 1%),
    0 128px 77px rgb(41 41 46 / 5%),
    0 57px 57px rgb(41 41 46 / 9%),
    0 14px 31px rgb(41 41 46 / 10%),
    0 0 0 rgb(41 41 46 / 10%);

  @include media-breakpoint-only(xs) {
    width: 100%;
    height: 100%;
    margin-top: 0;
    border-radius: 0;
    box-shadow: none;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;

    &-header {
      margin: 2.5rem 2.5rem 1.25rem;

      @include media-breakpoint-down(sm) {
        margin: 2.5rem 1.5rem 1.25rem;
      }
    }

    &-view {
      flex-basis: 150px;
      flex-grow: 1;
      height: 100%;
      padding: 0 2.5rem;
      overflow-y: auto;

      @include media-breakpoint-down(sm) {
        max-height: unset;
        padding: 0 1.5rem;
      }

      &.hide-scrollbar {
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    &-header ~ &-view {
      padding-top: 0.25rem;
    }
  }
}
</style>
