import { defineStore } from 'pinia';
import { getWidgetConfig } from '@swapin-hub/api/config';

export const useConfigStore = defineStore('config', {
  state: () => ({
    config: null,
    configUrl: null,
  }),

  actions: {
    async getConfig(key, params) {
      const data = await getWidgetConfig(key, params);

      this.config = data;

      return data;
    },
  },
});
