<template>
  <RouterView />
  <ModalsContainer />
</template>

<script setup>
import { RouterView, useRouter } from 'vue-router';
import { ModalsContainer } from '@swapin/ui/Modal';
import { setHeader } from '@swapin-hub/api';

import { CONFIG_KEY } from '@/const';
import { useConfigStore } from '@/stores/config';
import { useCoinsStore } from '@/stores/coins';

const router = useRouter();

const configStore = useConfigStore();
const coinsStore = useCoinsStore();

const searchParameters = new URL(window.location.href).searchParams;
const configKey = searchParameters.get('apiKey') || CONFIG_KEY;
const routeQuery = Object.fromEntries(searchParameters.entries());

setHeader('widget-api-key', configKey);

configStore.configUrl = window.location.search;
configStore.getConfig(configKey, routeQuery)
  // eslint-disable-next-line unicorn/prefer-top-level-await
  .catch(() => {
    router.replace({ name: 'not-available' });
  });

coinsStore.getCoins();
</script>
